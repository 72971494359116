import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FreshdeskService {

  private freshUrl = "https://netlock-support.freshdesk.com/api/v2/"; // URL to web api

  constructor(private http: HttpClient) { }

  public getTicket(ticketId): Observable<any> {
    return this.http.get<any>(this.freshUrl+"tickets/"+ticketId, {
      headers: new HttpHeaders()
        .set('Authorization',  'Basic c21CUlB6YWVuYW85M0h4cWltOlg=')
    });
  }

  public getConversation(ticketId): Observable<any> {
    return this.http.get<any>(this.freshUrl+"tickets/"+ticketId+"/conversations", {
      headers: new HttpHeaders()
        .set('Authorization',  'Basic c21CUlB6YWVuYW85M0h4cWltOlg=')
    });
  }
}
