import { Component, OnInit } from '@angular/core';
import { FreshdeskService } from '../../servicios/freshdesk.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ver-ticket',
  templateUrl: './ver-ticket.component.html',
  styleUrls: ['./ver-ticket.component.css']
})
export class VerTicketComponent implements OnInit {

  public ticket: any;

  public conversation: any;

  constructor( private freshdeskService: FreshdeskService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
        if (params.ticket)
          {
            this.freshdeskService.getTicket(params.ticket).subscribe(
            data => ( this.ticket = data, console.log(data) )
            );

            this.freshdeskService.getConversation(params.ticket).subscribe(
              data => ( this.conversation = data, console.log(data) )
              );
          }
      }
    );

    
    
    
  }

}
