import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerTicketComponent } from './componentes/ver-ticket/ver-ticket.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';




@NgModule({
  declarations: [  VerTicketComponent ],
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule
  ],  
  exports: [ VerTicketComponent ]
})
export class TicketModule { }
