<mat-card>
    
    <div *ngIf="ticket">
            <mat-card-title>{{ ticket.subject }}</mat-card-title>
            <mat-card-subtitle>{{ ticket.created_at | date:'medium' }}</mat-card-subtitle>
    
          <mat-card-content>
            <p innerHTML="{{ ticket.description }}">
      
            </p>
          </mat-card-content>
          
    </div>

<div *ngIf="conversation; else loading">
    <div *ngFor="let say of conversation; ">
       
        <mat-card>

        
        <mat-card-subtitle>{{ say.from_email }} {{ say.created_at | date:'medium' }}</mat-card-subtitle>
        <mat-card-content>
            <p innerHTML="{{ say.body }}">
              The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
              A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
              bred for hunting.
            </p>
          </mat-card-content>
          </mat-card>
    </div>
</div>
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</mat-card>