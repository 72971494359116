<mat-sidenav-container>
  <section>
    <mat-toolbar color="primary">
    <span>
      <img src="https://s3.eu-central-1.amazonaws.com/euc-cdn.freshdesk.com/data/helpdesk/attachments/production/77007533576/logo/DsNN-wIYhjJmr_i62Tuk-fwAi7lnr-ePXQ.png" />
    </span>

  </mat-toolbar>
  <mat-divider></mat-divider>

  </section>
  <section>
    <app-ver-ticket></app-ver-ticket>
  </section>
</mat-sidenav-container>
<router-outlet></router-outlet>